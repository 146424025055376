<template>

  <div class="app de">
    <div class="hd">
      <span @click="$router.back()">返回</span>
      <div>注册</div>
     <div></div>

    </div>
    <div class="ct">
      <md-field>
        <md-input-item
            ref="name"
            preview-type="text"
            v-model="user.phone"
            title="用户手机号码"
            type="phone"
            placeholder="请填写手机号码"
            is-title-latent
        ></md-input-item>
        <md-input-item
            ref="id"
            type="password"
            preview-type="text"
            title="密码"
            v-model="user.pwd"
            placeholder="请填写密码"
            is-title-latent
        ></md-input-item>
        <md-input-item
            ref="id"
            type="password"
            preview-type="text"
            title="确认密码"
            v-model="user.repwd"
            placeholder="请重新输入上面的密码"
            is-title-latent
        ></md-input-item>
      </md-field>
      <md-button type="primary" @click="doreg">立即注册</md-button>
    </div>

  </div>

</template>

<script>
export default {
name: "reg",
  data(){
    return {
      user:{
        phone:'',
        pwd:'',
        repwd:''
      }
    }
  },
  methods:{
    async doreg(){
          if(this.user.pwd !== this.user.repwd) return this.$toast.info('两次输入的密码不一致')
          if(!this.user.phone || this.user.phone.length!==11) return this.$toast.info('请填写正确的手机号码')
          const res = await this.$api.post('/mapi/reg',{ phone:this.user.phone,pwd:this.user.pwd})
          const { success,info } = res.data;
          if(success){
            this.$toast.succeed('注册成功')
            this.$router.replace({name:'login'})
          }else{
            this.$toast.failed(info)
          }


    }
  }

}
</script>

<style scoped>

</style>
